import React, { Component } from 'react';
import '../CSS/navBar.css';
import '../CSS/styles.css';
import { Link } from 'react-router-dom';
import Resume from '../Download/BenjaminAnoh.pdf';
// fixed - top;
class NavBar extends Component {
  render() {
    return (
      <header>
        <nav className='mt-3 navbar navbar-expand-xxl navbar-light navbar-brand'>
          <div className='container-fluid'>
            <a className='navbar-brand' href='#'></a>
            <button
              className='navbar-toggler'
              type='button'
              data-bs-toggle='collapse'
              data-bs-target='#navbarCollapse'
              aria-controls='navbarCollapse'
              aria-expanded='false'
              aria-label='Toggle navigation'>
              <span className='navbar-toggler-icon'></span>
            </button>
            <div
              className='container collapse navbar-collapse'
              id='navbarCollapse'>
              <ul className='navbar-nav me-auto mb-2 mb-md-0'>
                <li className='nav-item'>
                  <Link className='titleStyle fw-bold' to='/'>
                    Benjamin Anoh
                  </Link>
                </li>
              </ul>
              <div className='text-end'>
                <ul className='navbar-nav me-auto mb-2 mb-md-0'>
                  <li className='nav-item'>
                    <Link className='nav-link ' to='/work'>
                      Work
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link className='nav-link' to='/'>
                      About
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </header>
    );
  }
}
export default NavBar;
