import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import Projects from './Components/Projects';
import NavBar from './Components/NavBar';
import GiftPlannerPolicies from './Components/GiftPlannerPolicies';
import Maintenance from './Components/Maintenance';
import FoodRecipesPolicies from './Components/FoodRecipesPolicies';
import PageNotFount from './Components/NotFound';
import AboutMe from './Components/About';
import Work from './Components/Work';
import ImageGallery from './Components/ImageGallery';

class App extends Component {
  render() {
    return (
      <main className='.container'>
        <NavBar />
        <div className='flex-shrink-0 body'>
          <Switch>
            <Route path='/maintenance' component={Maintenance} />
            <Route path='/gallery/:index' component={ImageGallery} />
            <Route path='/apps' component={Projects} />
            <Route path='/work' component={Work} />
            <Route
              path='/food-recipes-policies'
              component={FoodRecipesPolicies}
            />
            <Route path='/policies' component={GiftPlannerPolicies} />
            <Route path='/not-found' component={PageNotFount} />
            <Route path='/' exact component={AboutMe} />
            <Redirect to='/not-found' />
          </Switch>
        </div>
      </main>
    );
  }
}

export default App;
